<template>
    <comp-table ref="comp_table" title="数据库机构操作情况记录" :columns="columns" :dataList="dataList" :dataBefore="onDataBefore" @on-reset="onReset">
        <template v-slot:search="data">
            <Input v-model="data.search.keyword" style="width: 250px" placeholder="机构名称" />
        </template>
    </comp-table>
</template>

<script>
import Route from "../../communitymobilization/entity/Route"
import CompTable from "../../jointly/components/comp-table.vue"
import Request from "../../jointly/utils/request"

// class Req {
//     static get(url) {
//         return new Promise((resolve, reject) => {
//             const xhr = new XMLHttpRequest()

//             xhr.open("GET", "https://api.gateway.prod.liefengtech.com" + url, true)

//             xhr.setRequestHeader("Authorization", "b413d6a5-3fc4-4450-89ab-636ad3d99c4f")

//             xhr.onreadystatechange = function() {
//                 if (xhr.readyState === 4) {
//                     if (xhr.status === 200) {
//                         const data = JSON.parse(xhr.responseText)
//                         resolve(data.data || data.dataList)
//                     } else {
//                         reject(xhr.responseText)
//                     }
//                 }
//             }

//             xhr.send()
//         })
//     }
// }

export default {
    components: { CompTable },

    data() {
        return {
            columns: [
                {
                    title: "机构名称",
                    key: "orgName",
                    minWidth: 300,
                },
                {
                    title: "操作人数",
                    minWidth: 150,
                    render: (h, params) => {
                        return h(
                            "p",
                            {
                                style: {
                                    color: "#409EFF",
                                    cursor: "pointer",
                                },
                                on: {
                                    click: () => {
                                        Route.jump(`/monitoringdatabateorgpersonnel?o=${params.row.orgCode}&on=${params.row.orgName}&n=${this.$core.getUrlParam("n")}&s=${this.$core.getUrlParam("s")}&e=${this.$core.getUrlParam("e")}`)
                                    },
                                },
                            },
                            params.row.uv
                        )
                    },
                },
                {
                    title: "查看次数",
                    key: "pv",
                    minWidth: 150,
                },
            ],

            dataList: null,
        }
    },

    mounted() {
        this.getTableData()
    },

    methods: {
        getTableData() {
            Request.get(`/gateway/api/ck/SystemOperateLog/getFunctionRequestOrg?functionName=${this.$core.getUrlParam("n")}&startDate=${this.$core.getUrlParam("s")}&endDate=${this.$core.getUrlParam("e")}`).then(res => {
                this.dataList = res
            })
        },

        // 监听数据前置事件
        onDataBefore(data) {
            this.$refs.comp_table.keyword(data.keyword)
        },
    },
}
</script>
<style lang="less"></style>
